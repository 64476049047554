@use "../config/" as *;
.waf-head {
    .title {
        @extend %d-none;
    }
    .step {
        &-wrapper {
            @extend %w-100;
        }
        &-list {
            @extend %gap-15;
            @extend %w-100;
            @extend %flex-c-c;
            @extend %px-0;
            @extend %py-10;
            .step1 {
                .icon::before {
                    content: '1';
                }
            }
            .step2 {
                .icon::before {
                    content: '2';
                }
            }
            .step3 {
                .icon::before {
                    content: '3';
                }
            }
            .step-item {
                &.completed {
                    .icon::before {
                        @extend %success-800-bg;
                        @include icon(check-drop);
                        font-size: .8rem;
                    }
                }
                &.active {
                    .icon::before {
                        font-weight: 700;
                        @extend %primary-300-bg;
                        @extend %neutral-800;
                    }
                }
                &.completed,
                &.active {
                    .link-text {
                        @extend %neutral-800;
                    }
                }
            }
        }
    }
    .link {
        &-text {
            max-width: 5.5rem;
            line-height: 1.8rem;
            @extend %capitalize;
            @extend %text-center;
            @extend %flex-column-c-c;
            @extend %font-12-pb;
            @extend %neutral-200;
            @extend %gap-2;
        }
        &-anchor {
            @extend %px-0;
            @extend %flex-column-c-c;
            @extend %gap-2;
        }
    }
    .icon {
        &::before {
            content: '';
            width: 2.5rem;
            height: 2.5rem;
            pointer-events: none;
            @extend %rounded-radius;
            @extend %flex-c-c;
            @extend %font-10-pm;
            @extend %neutral-200-bg;
            @extend %pure-white-900;
        }
    }
}
.summary-title {
    border-bottom: .1rem solid var(--neutral-1000);
    @extend %font-20-pb;
    @extend %neutral-800;
    @extend %capitalize;
    @extend %py-6;
    @extend %mb-6;
}
.summary-item {
    @include flex-config(flex, null, space-between, null);
    @extend %pb-6;
    .text {
        @extend %font-14-pr;
        @extend %neutral-600;
        @extend %capitalize;
    }
    .price {
        @extend %font-14-pb;
    }
}
.summary-item:last-child {
    @extend %pb-5;
}
.summary-item.summary-item-discount {
    .price {
        @extend %error-800;
    }
}
.summary-item.summary-item-total {
    border-top: .1rem solid var(--neutral-1000);
    @extend %py-5;
    .text {
        @extend %font-20-pb;
        @extend %neutral-800;
    }
    .price {
        @extend %font-20-pb;
        @extend %neutral-800;
    }
}
.product-wrap {
    gap: 1.6rem;
    @extend %mb-5;
    @extend %flex;
    @extend %w-100;
}
.meta-item {
    .text {
        margin-bottom: .5rem;
        @extend %font-12-pr;
        @extend %neutral-600;
        @extend %capitalize;
    }
    .amount {
        @extend %font-16-pr;
        @extend %neutral-800;
    }
    span {
        @extend %d-block;
    }
}
.subtotal {
    .amount {
        @extend %font-16-pb;
    }
    // .text {
    //     @extend %d-none;
    // }
}
.product-count {
    border-radius: var(--space-1);
    @include border(1, neutral-1200);
    @extend %p-2;
    @extend %flex-sb-c;
    @extend %gap-5;
    .count-value {
        @extend %font-12-pb;
        @extend %neutral-1300;
    }
    .decrement-count,
    .increment-count {
        height: 1.8rem;
        width: 1.8rem;
        .btn-txt {
            @extend %d-block;
            @extend %w-100;
            @extend %font-0;
        }
    }
    .decrement-count {
        .btn-txt::before {
            @include icon(minus, 12);
        }
    }
    .increment-count {
        .btn-txt::before {
            @include icon(add, 12);
        }
    }
}
.shopping-cart-wrapper {
    .article-thumbnail {
        background-color: hsl(var(--hsl-neutral-100)/0.2);
        border-radius: 0.5rem;
        @extend %w-30;
        @extend %p-3-4;
    }
    .article-title {
        @include truncate-text(2, 40);
        @extend %font-16-psb;
        @extend %secondary-800;
        @extend %capitalize;
    }
    .btn-remove {
        .btn-text {
            @extend %font-14-pm;
            @extend %error-800;
            @extend %capitalize;
            &::before {
                padding-right: var(--space-1);
                @include user-setting(delete, 12);
            }
        }
    }
}
.btn-disabled {
    opacity: .5;
    pointer-events: none;
}
@media (min-width: $tablet-min-width) {
    .waf-head {
        .link {
            &-text {
                font-size: 1.6rem;
                max-width: unset;
                flex-direction: row;
                gap: var(--space-3);
                &::before {
                    font-size: 1.4rem;
                    width: 3.4rem;
                    height: 3.4rem;
                }
            }
        }
    }
}