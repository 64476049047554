@use "../config/" as *;
.waf-order-complete {
    @extend %pure-white-900-bg;
    min-height: calc(var(--window-inner-height) - var(--header-height));
    @extend %px-4;
    @extend %pb-8;
    .order {
        &-card {
            box-shadow: 0 3.2rem 4.8rem -0.2rem hsl(var(--hsl-neutral-1300)/0.1);
            @extend %px-4;
            @extend %half-radius;
            @extend %flex-c-c;
            &-wrapper {
                @extend %text-center;
            }
        }
        &-message {
            @extend %font-20-pm;
            @extend %neutral-1200;
            &-wrap {
                gap: var(--space-2);
                @extend %flex-c-c;
                .image {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
        &-title {
            line-height: 3.8rem;
            @extend %mt-3;
            @extend %font-32-pb;
            @extend %neutral-800;
        }
        &-info {
            line-height: 2rem;
            @extend %my-8;
            @extend %font-14-pr;
            @extend %neutral-600;
        }
        &-details {
            @extend %flex-c-n;
            &-item {
                gap: var(--space-8);
                @extend %flex-c-c;
                @extend %mb-8;
            }
            &-label {
                width: 11.5rem;
                @extend %font-14-pr;
                @extend %neutral-600;
            }
            &-value {
                width: 9.6rem;
                @extend %font-14-pb;
                @extend %neutral-800;
            }
            &-label,
            &-value {
                text-align: left;
                @extend %d-block;
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-order-complete {
        .order {
            &-card {
                max-width: 74rem;
                margin-inline: auto;
                padding: var(--space-12);
            }
            &-message {
                font-size: 2.4rem;
            }
            &-title {
                font-size: 4rem;
                line-height: 4.6rem;
                max-width: 50rem;
            }
            &-details-value {
                width: 15rem;
            }
        }
    }
}